import { get, isEqual } from 'lodash';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { apiSelector, ApiReducerState } from '@ackee/redux-utils';

import { EntityKey, ApiReducerKey, SortingType } from 'constants/index';
import type { Search, Sorting } from 'constants/index';

import { compareValueByType } from 'services/utils';

import { defaultCompare } from 'modules/table';
import { coordinatesSelector } from 'modules/entities/modules/elevator-coordinates/services/selectors';

import type { Workorder, WorkorderGroup } from '../../types';

import { WorkOrderState, WorkorderStateGroup, WorkorderField } from '../../types';
import { getPropertyUnitAdress, isSupportedState } from '../utils';
import { SUPPORTED_WORKORDER_STATES } from '../../constants';

export const selectWorkordersApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.WORKORDERS, ApiReducerKey.LIST);

export const selectWorkordersEntityList = (state): any[] => state.entities[EntityKey.WORKORDERS][ApiReducerKey.LIST];

export const selectWorkorders = createSelector([selectWorkordersEntityList], (workorders: Workorder[]) => {
    if (!workorders) {
        return [];
    }
    return workorders.map(workorder => ({
        ...workorder,
    }));
});

export const selectWorkordersCount = createSelector([selectWorkorders], workorders => workorders.length);

const WorkorderStateAndGroup = [...SUPPORTED_WORKORDER_STATES];
export const selectWorkordersCountByState = createSelector([selectWorkorders], workorders =>
    WorkorderStateAndGroup.reduce<Record<WorkOrderState | WorkorderStateGroup, number>>((counts, filter) => {
        counts[filter] = workorders.filter(({ Status__c }) => {
            switch (filter) {
                case WorkOrderState.UNKNOWN: {
                    return !isSupportedState(Status__c) || Status__c === WorkOrderState.UNKNOWN;
                }
                default: {
                    return Status__c === filter;
                }
            }
        }).length;
        return counts;
    }, {} as Record<WorkOrderState | WorkorderStateGroup, number>),
);

export const stateOrderMap = {
    [WorkOrderState.NEW]: 0,
    [WorkOrderState.IN_CLARIFICATION]: 1,
    [WorkOrderState.NOT_RELEVANT]: 2,
    [WorkOrderState.RUNNING]: 3,
    [WorkOrderState.WAITING_FOR_ASSISTANT]: 4,
    [WorkOrderState.WAITING_FOR_TECHNICIAN]: 5,
    [WorkOrderState.TECHNICIAN_REVIEW_REQUIRED]: 6,
    [WorkOrderState.FINISHED]: 7,
    [WorkOrderState.REJECTED]: 8,
    [WorkOrderState.TECHNICAL_Q_CHECK_DONE]: 9,
    [WorkOrderState.IN_REVIEW]: 10,
    [WorkOrderState.FULFILLED]: 11,
    [WorkOrderState.FUTILE_TRAVEL]: 12,
    [WorkOrderState.UNKNOWN]: 13,
};

export const getWordorderStateOrder = (workorder: Workorder) =>
    stateOrderMap[isSupportedState(workorder?.Status__c) ? workorder?.Status__c : WorkOrderState.UNKNOWN];

export function stateComparatorFactory(type: SortingType) {
    return (a: Workorder, b: Workorder) => {
        const orderA = getWordorderStateOrder(a);
        const orderB = getWordorderStateOrder(b);

        return type === SortingType.ASC ? orderA - orderB : orderB - orderA;
    };
}

const sortWorkordersFactory = (sorting?: Sorting) => {
    switch (sorting?.field) {
        case WorkorderField.STATUS: {
            return stateComparatorFactory(sorting.type);
        }
        // case ElevatorField.PROPERTY_UNIT_BUSINESS_UNIT: {
        //     return stringComparatorFactory(sorting, '');
        // }
        default: {
            return defaultCompare;
        }
    }
};

export const selectFilteredWorkorders = createSelector(
    [
        selectWorkorders,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (workorders, search) =>
        workorders.filter(workorder =>
            search.every(({ field, query }) => {
                if (field === WorkorderField.STATUS) {
                    return SUPPORTED_WORKORDER_STATES.includes(workorder.Status__c) && true
                        ? workorder.Status__c && query.includes(workorder.Status__c)
                        : query.includes(WorkOrderState.UNKNOWN);
                }
                return compareValueByType({
                    value: get(workorder, field),
                    query,
                });
            }),
        ),
);

export const selectSortedWorkorders = createSelector(
    [
        selectFilteredWorkorders,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => [...docs].sort(sortWorkordersFactory(sorting)),
);

const createDeepComparisonSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selecWorkorderGroups = createDeepComparisonSelector(
    [selectSortedWorkorders, coordinatesSelector],
    (workorders, coordinates) => {
        const workorderGroupsMap = workorders.reduce((groups, workorder) => {
            const state = workorder?.Status__c || '';
            const propertyUnitAddress = getPropertyUnitAdress(workorder);
            const coordinate = coordinates[propertyUnitAddress];
            // or if the property unit has no coordinates
            if (!coordinate?.lng && !workorder?.Latitude__c) {
                return groups;
            }

            if (!groups.has(propertyUnitAddress + state)) {
                groups.set(propertyUnitAddress + state, {
                    propertyUnit: {
                        address: propertyUnitAddress,
                        //...elevator.propertyUnit,
                        ...coordinate,
                        ...(workorder?.Latitude__c && workorder?.Longitude__c
                            ? { lat: workorder.Latitude__c, lng: workorder.Longitude__c }
                            : {}),
                    },
                    workorders: [workorder],
                });
            } else {
                groups.get(propertyUnitAddress + state).workorders.push(workorder);
            }

            return groups;
        }, new Map<string, WorkorderGroup>());

        return [...workorderGroupsMap.values()];
    },
);
