import { Form, Image, Typography } from 'antd';
import { FloatingLabel, Input, Select } from 'modules/form';
import React, { useEffect, useState } from 'react';
import userSvg from 'assets/images/user.svg';
import close from 'assets/images/close.svg';
import * as felaRules from './InviteUser.styles';
import { useFelaEnhanced } from 'hooks';
import { useIntl } from 'react-intl';
import { useTeamDialog } from 'modules/teams/hooks/useTeamDialog';
import TeamDialogFooter from '../TeamDialogFooter';
import type { FormProps } from 'antd';
import { useInviteUser } from 'modules/entities/modules/teams/hooks';
import { useNotifications } from 'modules/ui';
import { MinusOutlined } from '@ant-design/icons';
import { useFetchUsersRolesPermissions } from 'modules/entities/modules/teams/hooks/useFetchUsersRolesPermissions';
import { useUser } from 'modules/auth';

const newUser = {
  emailId: '',
  role: '',
};

const defaultUsers = [{ emailId: '', role: '' }];

const InviteUser = () => {
  const { styles, css } = useFelaEnhanced(felaRules);
  const { setState } = useTeamDialog();
  const user = useUser();
  const intl = useIntl();
  const [users, setUsers] = useState(defaultUsers);
  const { handleSubmit, api, resetAction } = useInviteUser();
  const notifications = useNotifications();
  const allRoles = useFetchUsersRolesPermissions();
  // const showMemberText = useMemo(() => users.find(user => user.role === 'member'), [users]);
  // const showAdminText = useMemo(() => users.find(user => user.role === 'admin'), [users]);
  // const showGuestText = useMemo(() => users.find(user => user.role === 'guest'), [users]);
  const [form] = Form.useForm();

  useEffect(() => {
    let main = 'invite.users.request.success.title';
    let description = 'invite.users.request.success.description';

    if (api.success) {
      notifications.success({
        message: intl.formatMessage({
          id: main,
        }),
        description: intl.formatMessage({
          id: description,
        }),
      });

      setState(null);
      resetAction();
    }

    if (api.error) {
      notifications.error({
        message: intl.formatMessage({
          id: api.error,
        }),
      });
      resetAction();
      form.resetFields();
    }
  }, [api, intl, notifications, setState, form, resetAction]);

  const addUser = () => setUsers(oldUser => [...oldUser, newUser]);

  const hasDifferentDomain = (users, targetDomain) => {
    return users.some(user => {
      const emailDomain = user.emailId.split('@')[1]; // Extract the domain from email
      return emailDomain !== targetDomain;
    });
  };

  const onFinish: FormProps<any>['onFinish'] = () => {
    const targetDomain = user.email?.split('@')[1];

    if (hasDifferentDomain(users, targetDomain)) {
        notifications.error({
            message: intl.formatMessage({
              id: 'same.email.domain.error',
            }, {
                email: targetDomain
            }),
          });
    } else {
      handleSubmit({
        users,
        email: 'userId',
      });
    }
  };

  const removeUser = (index: number) => {
    setUsers(u => u.filter((user, i) => i !== index));
  };

  const onValuesChange = (_, values) => {
    const newUsers = Object.keys(values).reduce((acc, key) => {
      const [property, index] = key.split('-');

      if (!acc[index]) {
        acc[index] = {};
      }

      acc[index][property] = values[key];

      return acc;
    }, []);

    setUsers(newUsers);
  };

  return (
    <div className={styles.inviteUser}>
      <div className={styles.inviteUserHeading}>
        <Image src={userSvg} alt="user" preview={false} />
        <Typography.Title level={4} className={styles.title}>
          {intl.formatMessage({ id: 'teams.invite.user' })}
        </Typography.Title>
      </div>
      <Typography.Text className={styles.text}>
        {intl.formatMessage({ id: 'teams.invite.user.description' })}
      </Typography.Text>
      <Form onValuesChange={onValuesChange} className={styles.form} onFinish={onFinish} autoComplete="off" form={form}>
        {users.map((user, index) => (
          <div className={styles.team} key={index}>
            <FloatingLabel
              htmlFor={`emailId-${index}`}
              floating={Boolean(user.emailId)}
              label={intl.formatMessage({
                id: 'teams.email.type.label',
              })}
              fontSize="0.8rem !important"
            >
              {({ onFocus, onBlur }) => (
                <Form.Item
                  name={`emailId-${index}`}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'form.validation.required.email' }),
                    },
                    {
                      type: 'email',
                      message: intl.formatMessage({ id: 'form.validation.email' }),
                    },
                  ]}
                >
                  <Input
                    className={css({
                      height: '48px',
                      paddingTop: Boolean(user.emailId) ? '20px' : 'auto',
                      width: '100%',
                    })}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    id={`emailId-${index}`}
                    data-testid="email"
                    value={user.emailId}
                    placeholder={intl.formatMessage({ id: 'teams.email.type.label' })}
                  />
                </Form.Item>
              )}
            </FloatingLabel>
            <FloatingLabel
              htmlFor="role"
              floating={Boolean(user.role)}
              label={intl.formatMessage({
                id: 'teams.role.type.label',
              })}
              fontSize="0.8rem !important"
            >
              {({ onFocus, onBlur }) => (
                <Form.Item
                  name={`role-${index}`}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'form.validation.required.role' }),
                    },
                  ]}
                >
                  <Select
                    id="role"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    options={allRoles.roles}
                    placeholder={intl.formatMessage({ id: 'teams.role.type.label' })}
                    fullWidth
                    floatingLabel
                    data-testid="role"
                  />
                </Form.Item>
              )}
            </FloatingLabel>
            {users.length > 1 && (
              <button type="button" onClick={() => removeUser(index)} className={styles.button}>
                <MinusOutlined />
              </button>
            )}
            {index === users.length - 1 && (
              <button onClick={addUser} className={styles.button}>
                <Image src={close} alt="close" preview={false} />
              </button>
            )}
          </div>
        ))}
        {/* {showAdminText && (
                    <Typography.Text className={styles.text}>
                        <strong>{intl.formatMessage({ id: 'teams.invite.user.admin' })}</strong>{' '}
                        {intl.formatMessage({ id: 'teams.invite.user.admin.role.text' })}
                    </Typography.Text>
                )}
                {showMemberText && (
                    <Typography.Text className={styles.text}>
                        <strong>{intl.formatMessage({ id: 'teams.invite.user.member' })}</strong>{' '}
                        {intl.formatMessage({ id: 'teams.invite.user.member.role.text' })}
                    </Typography.Text>
                )}
                {showGuestText && (
                    <Typography.Text className={styles.text}>
                        <strong>{intl.formatMessage({ id: 'teams.invite.user.guest' })}</strong>{' '}
                        {intl.formatMessage({ id: 'teams.invite.user.guest.role.text' })}
                    </Typography.Text>
                )} */}
        {/* <Typography.Text className={styles.learnMore}>
                    {intl.formatMessage({ id: 'teams.invite.user.learn.more' })}{' '}
                    <a href="/">{intl.formatMessage({ id: 'teams.invite.user.click.here' })}</a>
                </Typography.Text> */}
        <TeamDialogFooter disabled={api.inProgress} onclose={() => form.resetFields()} />
      </Form>
    </div>
  );
};

export default InviteUser;
