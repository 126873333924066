export enum WorkOrderState {
    NEW = 'new',
    IN_CLARIFICATION = 'in clarification',
    NOT_RELEVANT = 'not_relevant',
    RUNNING = 'running',
    WAITING_FOR_ASSISTANT = 'waiting_for_assistant',
    WAITING_FOR_TECHNICIAN = 'waiting_for_technician',
    TECHNICIAN_REVIEW_REQUIRED = 'TECHNICIAN_REVIEW_REQUIRED',
    FINISHED = 'finished',
    REJECTED = 'Rejected',
    TECHNICAL_Q_CHECK_DONE = 'Technical Q-Check done',
    IN_REVIEW = 'In Review',
    FULFILLED = 'FULFILLED',
    UNKNOWN = 'UNKNOWN',
    FUTILE_TRAVEL = 'Futile Travel'
}

export enum WorkorderStateGroup {
    UNKNOWN = 'UNKNOWN',
}
export interface Workorder {
    Id: string;
    Name: string;
    Status__c: WorkOrderState;
    Street__c: string;
    City__c: string;
    Zip_Code__c: string;
    Latitude__c: number;
    Longitude__c: number;
}

interface PropertyUnit {
    Latitude__c: number;
    Longitude__c: number;
}

interface Elevator {
    PropertyUnit__r: PropertyUnit;
}

export interface WorkorderGroup {
    propertyUnit: Elevator['PropertyUnit__r'] &
        google.maps.LatLngLiteral & {
            address: string;
        };
    workorders: Workorder[];
}
export enum ElevatorField {
    STATUS = 'status',
    FACTORY_NUMBER = 'factoryNumber',
    PROPERTY_UNIT_STREET = 'propertyUnit.street',
    PROPERTY_UNIT_POSTAL_CODE = 'propertyUnit.postalCode',
    PROPERTY_UNIT_CITY = 'propertyUnit.city',
    PROPERTY_UNIT_BUSINESS_UNIT = 'propertyUnit.businessUnit',
    HARDWARE = 'devices',
    OWNER = 'owner',
}
export enum WorkorderField {
    NAME = 'Name',
    STATUS = 'Status__c',
    CITY = 'City__c',
    STREET = 'Street__c',
    ZIP_CODE = 'Zip_Code__c',
    PORTFOLIO = 'Portfolio',
    TECHNICIAN_NAME = 'TechnicanName',
    ASSISTANT_NAME = 'AssistantName'
  }
  