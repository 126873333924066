import type { TRule } from 'fela';
import { WorkOrderState } from 'modules/entities/modules/workorders';
import { Theme } from 'styles/theme';

export const icon: TRule<{ state?: WorkOrderState; theme: Theme }> = ({ theme, state }) => {
  let color;
  switch (state) {
    case WorkOrderState.REJECTED:
      color = theme.colors.statusHighAlert;
      break;
    case WorkOrderState.RUNNING:
      color = theme.colors.statusSuccess;
      break;
    case WorkOrderState.WAITING_FOR_ASSISTANT:
      color = theme.colors.borderPrimaryHover;
      break;
    case WorkOrderState.NEW:
      color = theme.colors.statusInfo;
      break;
    case WorkOrderState.IN_CLARIFICATION:
      color = theme.colors.statusWarning;
      break;
    case WorkOrderState.NOT_RELEVANT:
      color = theme.colors.statusInactive;
      break;
    case WorkOrderState.WAITING_FOR_TECHNICIAN:
      color = theme.colors.pinkColor;
      break;
    case WorkOrderState.TECHNICIAN_REVIEW_REQUIRED:
      color = theme.colors.lightBlue;
      break;
    case WorkOrderState.FINISHED:
      color = theme.colors.outlineStatusSuccess;
      break;
    case WorkOrderState.TECHNICAL_Q_CHECK_DONE:
      color = theme.colors.info;
      break;
    case WorkOrderState.IN_REVIEW:
      color = theme.colors.statusWarning;
      break;
    case WorkOrderState.FULFILLED:
      color = theme.colors.simplifa2;
      break;
    case WorkOrderState.FUTILE_TRAVEL:
      color = theme.colors.darkGreen;
      break;
    case WorkOrderState.UNKNOWN:
      color = theme.colors.statusInfo;
      break;
    default:
      color = theme.colors.icon;
  }

  return { color, fontSize: '1.3rem' };
};
