import React, { useMemo } from 'react';

import { useFelaEnhanced } from 'hooks';

import { URL_ARRAY_SEPARATOR } from 'constants/index';

import { Icon, IconType } from 'modules/ui';
import { StatusFilter, SHOW_ALL } from 'modules/table';
import type { StatusFilterProps } from 'modules/table';
import { ElevatorState, ElevatorStateGroup } from 'modules/entities/modules/elevators';

import * as felaRules from './MapStatusFilter.rules';
import { getEnumKeyByValue, useWorkordersCountByState, WorkOrderState } from 'modules/entities/modules/workorders';
import { WorkOrderStatusIcon } from 'modules/workOrders/components/WorkOrderStatusIcon';

// hard coded filters
const filters = [
    WorkOrderState.NEW,
    WorkOrderState.IN_CLARIFICATION,
    WorkOrderState.NOT_RELEVANT,
    WorkOrderState.RUNNING,
    WorkOrderState.WAITING_FOR_ASSISTANT,
    WorkOrderState.WAITING_FOR_TECHNICIAN,
    WorkOrderState.TECHNICIAN_REVIEW_REQUIRED,
    WorkOrderState.FINISHED,
    WorkOrderState.REJECTED,
    WorkOrderState.TECHNICAL_Q_CHECK_DONE,
    WorkOrderState.IN_REVIEW,
    WorkOrderState.FULFILLED,
    WorkOrderState.FUTILE_TRAVEL
];

type StatusFilter = StatusFilterProps<ElevatorState | ElevatorStateGroup | WorkOrderState>['statuses'][number];

export interface MapStatusFilterProps {
    value: string | undefined;
    onChange: (value: string) => void;
}

export const MapStatusFilter = ({ value, onChange }: MapStatusFilterProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const countByState = useWorkordersCountByState();

    const filterValue = useMemo(() => (value ? value.split(URL_ARRAY_SEPARATOR) : undefined), [value]);

    const states = filters.map<StatusFilter>(state => ({
        icon: <WorkOrderStatusIcon state={state} extend={{ icon: rules.icon }} />,
        value: state,
        count: countByState[state],
        messageId: `page.workorders.filter.${getEnumKeyByValue(WorkOrderState, state)}`,
    }));

    states.push({
        icon: <WorkOrderStatusIcon extend={{ icon: rules.icon }} />,
        value: ElevatorStateGroup.UNKNOWN,
        count: countByState[ElevatorStateGroup.UNKNOWN],
        messageId: `page.workorders.filter.${ElevatorStateGroup.UNKNOWN}`,
    });

    return (
        <StatusFilter<ElevatorState | ElevatorStateGroup | WorkOrderState>
            allMessageId="page.workorders.filter.SHOW_ALL"
            allIcon={<Icon type={IconType.ELEVATOR} className={styles.icon} />}
            statuses={states}
            value={filterValue}
            onChange={e => {
                if (e.target.value === SHOW_ALL) {
                    onChange(null);

                    return;
                }

                onChange(
                    e.target.checked
                        ? [...(filterValue ?? []), e.target.value].join(URL_ARRAY_SEPARATOR)
                        : (filterValue ?? []).filter(v => v !== e.target.value).join(URL_ARRAY_SEPARATOR),
                );
            }}
            extend={{
                container: rules.container,
            }}
        />
    );
};
