import { WorkorderGroup, WorkOrderState } from 'modules/entities/modules/workorders';

export interface MarkerWithWorkorderGroup extends google.maps.Marker {
    workorderGroup?: WorkorderGroup;
}

export enum MarkerIcon {
    green = 'map/markers/green.svg',
    yellow = 'map/markers/yellow.svg',
    red = 'map/markers/red.svg',
    blue = 'map/markers/blue.svg',
    grey = 'map/markers/grey.svg',
    orange = 'map/markers/orange.svg',
    purple = 'map/markers/purple.svg',
    lightGreen = 'map/markers/lightGreen.svg',
    pink = 'map/markers/pink.svg',
    darkGreen = 'map/markers/darkGreen.svg',
    black = 'map/markers/black.svg',
    lightBlue = 'map/markers/lightBlue.svg', 
    xtraLightBlue = 'map/markers/xtraLightBlue.svg',
    lightYellow = 'map/markers/lightYellow.svg',
}

export enum ClusterIcon {
    green = 'map/clusters/green.svg',
    yellow = 'map/clusters/yellow.svg',
    red = 'map/clusters/red.svg',
    blue = 'map/clusters/blue.svg',
    grey = 'map/clusters/grey.svg',
    orange = 'map/clusters/orange.svg',
    purple = 'map/clusters/purple.svg',
    lightGreen = 'map/clusters/lightGreen.svg',
    pink = 'map/clusters/pink.svg',
    darkGreen = 'map/clusters/darkGreen.svg',
    black = 'map/clusters/black.svg',
    lightBlue = 'map/clusters/lightBlue.svg', 
    xtraLightBlue = 'map/clusters/xtraLightBlue.svg',
    lightYellow = 'map/clusters/lightYellow.svg',
}

export const CLOSE_ICON = 'map/infoWindow/close.svg';

export const MARKER_TYPES = {
    [WorkOrderState.NEW]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.blue,
        clusterIcon: ClusterIcon.blue,
    },
    [WorkOrderState.IN_CLARIFICATION]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.yellow,
        clusterIcon: ClusterIcon.yellow,
    },
    [WorkOrderState.NOT_RELEVANT]: {
        labelColor: '#000000',
        markerIcon: MarkerIcon.grey,
        clusterIcon: ClusterIcon.grey,
    },
    [WorkOrderState.RUNNING]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.green,
        clusterIcon: ClusterIcon.green,
    },
    [WorkOrderState.WAITING_FOR_ASSISTANT]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.orange,
        clusterIcon: ClusterIcon.yellow,
    },
    [WorkOrderState.WAITING_FOR_TECHNICIAN]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.pink,
        clusterIcon: ClusterIcon.pink,
    },
    [WorkOrderState.TECHNICIAN_REVIEW_REQUIRED]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.lightBlue,
        clusterIcon: ClusterIcon.lightBlue,
    },
    [WorkOrderState.FINISHED]: {
        labelColor: '#000000',
        markerIcon: MarkerIcon.lightGreen,
        clusterIcon: ClusterIcon.lightGreen,
    },
    [WorkOrderState.REJECTED]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.red,
        clusterIcon: ClusterIcon.red,
    },
    [WorkOrderState.TECHNICAL_Q_CHECK_DONE]: {
        labelColor: '#000000',
        markerIcon: MarkerIcon.xtraLightBlue,
        clusterIcon: ClusterIcon.xtraLightBlue,
    },
    [WorkOrderState.IN_REVIEW]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.lightYellow,
        clusterIcon: ClusterIcon.lightYellow,
    },
    [WorkOrderState.FULFILLED]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.purple,
        clusterIcon: ClusterIcon.purple,
    },
    [WorkOrderState.FUTILE_TRAVEL]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.darkGreen,
        clusterIcon: ClusterIcon.darkGreen,
    },
    [WorkOrderState.UNKNOWN]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.black,
        clusterIcon: ClusterIcon.black,
    },
};

export const WORKORDER_STATE_BY_MARKER_ICON = {
    [MarkerIcon.blue]: WorkOrderState.NEW,
    [MarkerIcon.yellow]: WorkOrderState.IN_CLARIFICATION,
    [MarkerIcon.grey]: WorkOrderState.NOT_RELEVANT,
    [MarkerIcon.green]: WorkOrderState.RUNNING,
    [MarkerIcon.orange]: WorkOrderState.WAITING_FOR_ASSISTANT,
    [MarkerIcon.pink]: WorkOrderState.WAITING_FOR_TECHNICIAN,
    [MarkerIcon.lightBlue]: WorkOrderState.TECHNICIAN_REVIEW_REQUIRED,
    [MarkerIcon.lightGreen]: WorkOrderState.FINISHED,
    [MarkerIcon.red]: WorkOrderState.REJECTED,
    [MarkerIcon.xtraLightBlue]: WorkOrderState.TECHNICAL_Q_CHECK_DONE,
    [MarkerIcon.lightYellow]: WorkOrderState.IN_REVIEW,
    [MarkerIcon.purple]: WorkOrderState.FULFILLED,
    [MarkerIcon.black]: WorkOrderState.UNKNOWN,
};
